import React from 'react';
import {Alert, Snackbar} from "@mui/material";

const CustomToast = ({openToast, setOpenToast, message, type}) => {
    return (
        <Snackbar open={openToast} autoHideDuration={2000} onClose={() => setOpenToast(false)}>
            <Alert elevation={6} variant="filled" severity={type} >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomToast;
