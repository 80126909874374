import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import HomePage from "./components/home";
import NavBar from "./shared/NavBar";
import RequestProductPage from "./components/requestProduct";
import BannerPage from "./components/banner";
import PushNotificationPage from "./components/pushNotification";
import UserTrackingPage from "./components/userTracking";
import AddBannerPage from "./components/banner/section/addBanner";
import Addlifecarebanner from "./components/banner/section/addlifecarebanner";
import EditBannerPage from "./components/banner/section/editBanner";
import Editlifecarebanner from "./components/banner/section/editlifecarebanner";
import ChatPage from "./components/chat";
import {useDispatch, useSelector} from "react-redux";
import AuthPage from "./components/auth";
import {useEffect, useState} from "react";
import axios from "axios";
import {setUser} from "./redux/slices/UserSlice";
import FullScreenProgress from "./helpers/FullScreenProgress";
import ForgotPasswordPage from "./components/forgotPassword";

function App() {

    const user = useSelector(state => state.User.value);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios('/post/verifyAdminToken', {
            method: "post",
            withCredentials: true
        }).then(res => {
            if (res.data.key === 'success'){
                dispatch(setUser(res.data.data))
            }else {
                dispatch(setUser(null))
            }
            setLoading(false)
        }).catch(err => {
            console.log(err)
            dispatch(setUser(null))
            setLoading(false)
        })
    }, [dispatch])


    if (!loading) {
        if(user !== null) {
            return (
                <BrowserRouter>
                    <NavBar/>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/chat" element={<ChatPage/>}/>
                        <Route path="/request-product" element={<RequestProductPage/>}/>
                        <Route path="/push-notification" element={<PushNotificationPage/>}/>
                        <Route path="/banners/edit-banner/:id" element={<EditBannerPage />}/>
                        <Route path="/banners/edit-lifecare-banner/:id" element={<Editlifecarebanner />}/>
                        <Route path="/banners/add-banner" element={<AddBannerPage />}/>
                        <Route path="/banners/add-lifecare-banner" element={<Addlifecarebanner />}/>
                        <Route path="/banners" element={<BannerPage/>}/>
                        <Route path="/user-tracking" element={<UserTrackingPage/>}/>
                    </Routes>
                </BrowserRouter>
            );
        } else {
            return (
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={ <AuthPage />}/>
                        <Route path="/forgotPassword/:id" element={<ForgotPasswordPage />}/>
                    </Routes>
                </BrowserRouter>
            )
        }
    } else {
        return <FullScreenProgress open={loading} setOpen={setLoading}/>
    }

}

export default App;
