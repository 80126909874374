import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import {useMediaQuery} from "@mui/material";
import {Colors} from "../../helpers/Colors";
import ChatList from "./section/ChatList";
import ChatConversations from "./section/ChatConversations";

const ChatPage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')
    const is380pxBelow = useMediaQuery('(max-width:380px)')

    const navbarHeight = is380pxBelow ? '65px' : '75px';

    const useStyles = makeStyles({
        parentContainer: {
            height: `calc(100vh - ${navbarHeight})`,
            background: Colors.primary,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        container: {
            height: is770pxBelow ? '100%' : '70vh',
            width: is1025pxBelow ? (is770pxBelow ? '100%' : '90%') : '80%',
            // border: `1px solid ${Colors.light2}`,
            background: Colors.light,
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            borderRadius: '5px',
            display: 'flex',
            '& .chat-list': {
                width: is1025pxBelow ? (is770pxBelow ? (is430pxBelow ? '100%' : '35%') : '30%') : '25%',
                // borderRight: `1px solid ${Colors.light2}`,
            },
            '& .chat-conversations': {
                width: is1025pxBelow ? (is770pxBelow ? '65%' : '70%') : '75%',
            },
        },
    })

    const classes = useStyles()

    const [shoChat, setShoChat] = useState(true);

    return (
        <div className={classes.parentContainer}>
            <div className={classes.container}>
                {
                    is430pxBelow ?
                        <div className={'chat-list'}>
                            {
                                shoChat ?
                                    <ChatList setShoChat={setShoChat}/> :
                                    <ChatConversations setShoChat={setShoChat}/>
                            }
                        </div> :
                        <div className={'chat-list'}>
                            <ChatList />
                        </div>
                }
                {
                    !is430pxBelow &&
                        <div className={'chat-conversations'}>
                            <ChatConversations />
                        </div>
                }
            </div>
        </div>
    );
};

export default ChatPage;
