export const Colors = {
  primary: '#1E4F6D',
  secondary: '#F7AD00',
  primaryLight: '#ebf4fa',
  primary1: '#1E4F6D',
  primary2: '#2E5973',
  secondaryLight: '#fdf6e8',
  light: '#ffffff',
  light1: '#f2f2f2',
  light2: '#d9d9d9',
  light3: '#BEBEBE',
  light4: '#808080',
  dark: '#000000',
  dark1: '#1a1a1a',
  dark2: '#333333',
  dark3: '#404040',
  success: '#4bb543',
  error: '#da2126',
  warning: '#CB8705',
};
