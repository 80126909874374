import React from 'react';
import {makeStyles} from "@mui/styles";
import HomeBanner from "./section/HomeBanner";
import {Colors} from "../../helpers/Colors";
import HomeCards from "./section/HomeCards";
import HomeTable from "./section/HomeTable";
import {useMediaQuery} from "@mui/material";

const HomePage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles({
        container: {
            width: is1025pxBelow ? '90%' : '70%',
            padding: is430pxBelow ? '25px 0' : '35px 0',
            margin: '0 auto',
        },
        parentContainer: {
            background: Colors.primaryLight
        }
    })

    const classes = useStyles()

    return (
        <div className={classes.parentContainer}>
            <div className={classes.container}>
                <HomeBanner />
                <HomeCards />
                <HomeTable />
            </div>
        </div>
    );
};

export default HomePage;
