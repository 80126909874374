import React, {useEffect, useState} from 'react';
import {makeStyles} from "@mui/styles";
import AddNotification from "./section/AddNotification";
import AllNotification from "./section/AllNotification";
import {Colors} from "../../helpers/Colors";
import {useMediaQuery} from "@mui/material";
import axios from "axios";

const PushNotificationPage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is770pxBelow = useMediaQuery('(max-width:770px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles({
        container: {
            display: 'grid',
            gridTemplateColumns: is770pxBelow ? '1fr' : '1fr 2fr',
            background: Colors.primaryLight,
            gridGap: '25px',
            padding: is430pxBelow ? '35px 10px 25px' : '35px 25px 25px',
            minHeight: '100vh',
            '& .add-notification': {

            },
            '& .all-notification': {

            },
        }
    })

    const classes = useStyles()

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        axios.get('/get/notifications').then(res => {
            setNotifications(res.data)
        })
    }, []);

    return (
        <div className={classes.container}>
            <div className={'add-notification'}>
                <AddNotification setNotifications={setNotifications}/>
            </div>
            <div className={'all-notification'}>
                <AllNotification notifications={notifications} setNotifications={setNotifications}/>
            </div>
        </div>
    );
};

export default PushNotificationPage;
