import React from 'react';
import {Dialog} from "@mui/material";
import {makeStyles} from "@mui/styles";
import UserInfoDialogHeader from "./section/UserInfoDialogHeader";
import UserInfoDialogTable from "./section/UserInfoDialogTable";

const UserInfoDialog = ({open, setOpen}) => {

    const handleClose = () => {
        setOpen(false);
    };

    const useStyles = makeStyles({
        container: {
            padding: '20px'
        }
    })

    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
            >
                <div className={classes.container}>
                    <UserInfoDialogHeader setOpen={setOpen} />
                    <UserInfoDialogTable />
                </div>
            </Dialog>
        </div>
    );
};

export default UserInfoDialog;
