import React from 'react';
import {makeStyles} from "@mui/styles";
import {Colors} from "../../../helpers/Colors";
import {IoIosClose} from "react-icons/io";

const UserInfoDialogHeader = ({setOpen}) => {

    const useStyles = makeStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            '& h5': {
                fontSize: '18px',
                fontWeight: 'bold',
                color: Colors.dark3,
                margin: 0,
            },
            '& .icon': {
                fontSize: '32px',
                color: Colors.dark3,
            }
        }
    })

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <h5>User Tracking Table</h5>
            <IoIosClose className={'icon'} onClick={() => setOpen(false)}/>
        </div>
    );
};

export default UserInfoDialogHeader;
