import React, {useEffect, useState} from 'react';
import {useMediaQuery} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Colors} from "../../../../helpers/Colors";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {ImMobile} from "react-icons/im";
import {BiDesktop} from "react-icons/bi";
import CustomToast from "../../../../helpers/Toast";
import FullScreenProgress from "../../../../helpers/FullScreenProgress";

const EditBannerPage = () => {

    const is1025pxBelow = useMediaQuery('(max-width:1025px)')
    const is430pxBelow = useMediaQuery('(max-width:430px)')

    const useStyles = makeStyles({
        container: {
            minHeight: '100vh',
            width: is1025pxBelow ? '90%' : '70%',
            padding: is430pxBelow ? '25px 0' : '35px 0',
            margin: '0 auto',
            '& .card': {
                background: Colors.light,
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRadius: '10px',
                padding: '25px',
                '& .flex-con': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    '& .con': {
                        width: is430pxBelow ? '100%' : '48%',
                        '& .input-con': {
                            width: '100%',
                            marginBottom: '20px',
                            '& h6': {
                                fontSize: '16px',
                                color: Colors.dark3,
                                margin: '0 0 10px',
                            },
                            '& input': {
                                width: '100%',
                                maxWidth: '100%',
                                boxSizing: 'border-box;',
                                borderRadius: '5px',
                                border: `1px solid ${Colors.light2}`,
                                padding: '15px 10px',
                                '&:focus': {
                                    outline: 0
                                },
                                '&::placeholder': {
                                    fontSize: '14px',
                                }
                            }
                        },
                        '& .button-con': {
                            display: 'flex',
                            alignItems: 'center',
                            '& label': {
                                background: Colors.secondary,
                                padding: '10px 12px',
                                border: `1px solid ${Colors.secondary}`,
                                borderRadius: '5px',
                                transition: 'all .4s',
                                color: Colors.light,
                                fontSize: '14px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                fontFamily: 'inherit',
                                '&:hover': {
                                    transition: 'all .4s',
                                    background: Colors.light,
                                    color: Colors.secondary,
                                },
                            },
                            '& input': {
                                display: 'none'
                            },
                            '& .icon': {
                                fontSize: '30px',
                                color: Colors.dark3,
                                marginLeft: '8px'
                            },
                        },
                        '& .img-con': {
                            width: '100px',
                            height: '100px',
                            marginTop: '20px',
                            border: `1px solid ${Colors.light2}`,
                            borderRadius: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& img': {
                                width: '90%',
                                maxWidth: '90%',
                                maxHeight: '90%'
                            }
                        }
                    }
                },
                '& .upload-btn': {
                    background: Colors.primary,
                    padding: '10px 12px',
                    width: is430pxBelow ? '100%' : '70%',
                    margin: '35px auto 0',
                    border: `1px solid ${Colors.primary}`,
                    borderRadius: '5px',
                    transition: 'all .4s',
                    color: Colors.light,
                    fontSize: '16px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    fontFamily: 'inherit',
                    '&:hover': {
                        transition: 'all .4s',
                        background: Colors.light,
                        color: Colors.primary,
                    },
                }
            }
        },
        parentContainer: {
            height: '100%',
            background: Colors.primaryLight
        },
        title: {
            fontWeight: 'bold',
            color: Colors.dark3,
            margin: '0',
            fontSize: '20px'
        },
    })

    const classes = useStyles()

    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);

    const defaultData = {
        name: '',
        description: '',
        smallImage: null,
        largeImage: null,
    }

    const [banner, setBanner] = useState(defaultData);

    const navigate = useNavigate()

    const onUploadSmallImageClick = (e) => {
        setBanner({...banner, smallImage: e.target.files[0]});
    }

    const onUploadLargeImageClick = (e) => {
        setBanner({...banner, largeImage: e.target.files[0]});
    }

    const onDataChange = (e) => {
        setBanner({...banner, [e.target.name]: e.target.value})
    }

    const {id} = useParams();

    useEffect(() => {
        axios.get(`/get/banner?id=${id}`).then(res => {
            setBanner({...res.data, smallImage: null, largeImage: null})
        })
    }, [id]);


    const onUpdateClick = () => {
        const {
            _id, name, description, smallImageUrl, largeImageUrl, smallImage,
            largeImage
        } = banner
        if (name !== '' && description !== '') {
            setOpen(true)
            const data = new FormData()
            const isSmallImageChanged = smallImage !== null;
            const isLargeImageChanged = largeImage !== null;
            if (isSmallImageChanged) {
                data.append('smallImage', smallImage)
            }
            if (isLargeImageChanged) {
                data.append('largeImage', largeImage)
            }
            data.append('isSmallImageChanged', isSmallImageChanged.toString())
            data.append('isLargeImageChanged', isLargeImageChanged.toString())
            data.append('data', JSON.stringify({
                _id, name, smallImageUrl, largeImageUrl,
                description
            }))
            axios.put('/update/banner', data).then(res => {
                if (res.data.key === 'success') {
                    setOpen(false)
                    navigate(-1);
                } else {
                    setErrorMessage('Something went wrong')
                    setOpenError(true)
                }
            }).catch(err => {
                console.log(err)
                setOpen(false)
                setErrorMessage('Something went wrong')
                setOpenError(true)
            })
        } else {
            setErrorMessage('Fields should not be Empty')
            setOpenError(true)
        }
    }

    return (
        <div className={classes.parentContainer}>
            <div className={classes.container}>
                <h6 className={classes.title}>Edit Banner</h6>
                <div className={'card'}>
                    <div className={'flex-con'}>
                        <div className={'con'}>
                            <div className={'input-con'}>
                                <h6>Name</h6>
                                <input name={'name'} onChange={onDataChange} value={banner.name} type="text"
                                       placeholder={'Enter Banner Name'}/>
                            </div>
                            <div className={'button-con'}>
                                <label htmlFor={'small-image-picker'}>Upload Mobile banner</label>
                                <input id={'small-image-picker'} type={'file'} size={'60'}
                                       accept={'image/*'}
                                       onChange={onUploadSmallImageClick}
                                       multiple={false}/>
                                <ImMobile className={'icon'}/>
                            </div>
                            {
                                banner.smallImage !== null ?
                                    <div className={'img-con'}>
                                        <img src={URL.createObjectURL(banner.smallImage)} alt={'Banner'}/>
                                    </div> :
                                    <div className={'img-con'}>
                                        <img src={banner.smallImageUrl} alt={'Banner'}/>
                                    </div>
                            }
                        </div>
                        <div className={'con'} style={{marginTop: is430pxBelow ? '20px' : '0'}}>
                            <div className={'input-con'}>
                                <h6>Description</h6>
                                <input name={'description'} onChange={onDataChange} value={banner.description}
                                       type="text" placeholder={'Enter Banner Name'}/>
                            </div>
                            <div className={'button-con'}>
                                <label htmlFor={'large-image-picker'}>Upload Desktop banner</label>
                                <input id={'large-image-picker'} type={'file'} size={'60'}
                                       accept={'image/*'}
                                       onChange={onUploadLargeImageClick}
                                       multiple={false}/>
                                <BiDesktop className={'icon'}/>
                            </div>
                            {
                                banner.largeImage !== null ?
                                    <div className={'img-con'}>
                                        <img src={URL.createObjectURL(banner.largeImage)} alt={'Banner'}/>
                                    </div> :
                                    <div className={'img-con'}>
                                        <img src={banner.largeImageUrl} alt={'Banner'}/>
                                    </div>
                            }
                        </div>
                    </div>
                    <button className={'upload-btn'} onClick={onUpdateClick}>Update</button>
                </div>
            </div>
            <CustomToast type={'error'} openToast={openError} setOpenToast={setOpenError} message={errorMessage}/>
            <FullScreenProgress open={open} setOpen={setOpen}/>
        </div>
    );
};

export default EditBannerPage;
